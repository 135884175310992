import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Cart from './cart';
import ProductInCart from './product-in-cart';
import Order from './order';
import PaymentGateway from './payment-gateway';
import Product from './product';
import ProductCategory from './product-category';
import Payment from './payment';
import DiscountCode from './discount-code';
import OrderActivity from './order-activity';
import Queue from './queue';
import ProductTag from './product-tag';
import ProductAsset from './product-asset';
import Address from './address';
import PaymentMethod from './payment-method';
import Tag from './tag';
import Key from './key';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="cart/*" element={<Cart />} />
        <Route path="product-in-cart/*" element={<ProductInCart />} />
        <Route path="order/*" element={<Order />} />
        <Route path="payment-gateway/*" element={<PaymentGateway />} />
        <Route path="product/*" element={<Product />} />
        <Route path="product-category/*" element={<ProductCategory />} />
        <Route path="payment/*" element={<Payment />} />
        <Route path="discount-code/*" element={<DiscountCode />} />
        <Route path="order-activity/*" element={<OrderActivity />} />
        <Route path="queue/*" element={<Queue />} />
        <Route path="product-tag/*" element={<ProductTag />} />
        <Route path="product-asset/*" element={<ProductAsset />} />
        <Route path="address/*" element={<Address />} />
        <Route path="payment-method/*" element={<PaymentMethod />} />
        <Route path="tag/*" element={<Tag />} />
        <Route path="key/*" element={<Key />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
