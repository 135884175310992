import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Sidebar from './sidebar';

const RefundPolicy = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    contactType: '',
  });

  const contactType = {
    REFUND_POLICY: 'REFUND_POLICY',
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const formDataWithContactType = {
      ...formData,
      contactType: contactType.REFUND_POLICY,
    };
    const result = await axios.post('http://localhost:8094/api/email/contact-us', formDataWithContactType);

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
      contactType: '',
    });
  };

  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2>Refund Policy</h2>
        </div>
      </div>
      <div className="two-column-layout">
        <div className="content">
          <p>
            For all the <strong>SecureWebPayments</strong> products, like Driver Restore, Driver Whiz, System Optimizer Pro, System Doctor
            Pro and more, the full refund period is 60 days from the date of your purchased order. The purchases can be cancelled or
            refunded within the above mentioned time period to offer our user simple and hassle-free experiences.{' '}
          </p>
          <p>
            <strong>Full Refund : A user can apply for full refund if-</strong>
            <br />
            If we are unable to offer an updated driver that we have presented when you did the free scan for the functioning hardware
            devices.
            <br />
            If by mistake you have purchased multiple orders.
            <br />
            <strong>No Refund :</strong>
            <br />
            Once the 60 days refund policy has been expired, we are unable to provide you the refund.
          </p>
          <p>
            The refund can be processed whether you originally paid via Paypal or credit card. To request a refund please include your name,
            date of purchase as this will help expedite your request.
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <select id="subject" name="subject" value={formData.subject} onChange={handleChange} required>
                <option value="">-- Please Select --</option>
                <option value="Request my registration key">Request my registration key</option>
                <option value="Request a refund">Request a refund</option>
                <option value="I have a question about my bill">I have a question about my bill</option>
                <option value="How do I install my driver">How do I install my driver</option>
                <option value="Driver will not install">Driver will not install</option>
                <option value="Not listed">Not listed</option>
              </select>
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
