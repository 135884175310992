import React from 'react';
import Sidebar from './sidebar';
import GuestTracking from '../home-page/GuestTracking';

const OrderLookUp = () => {
  const handleOrderLookupClick = () => {
    // Redirect to the desired page
    window.location.href = '/GuestTracking';
  };

  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2> Order Look Up</h2>
        </div>
      </div>
      <div className="two-column-layout">
        <div className="content">
          <p>
            Do you have a pending order, a previously placed order, or an automatic reorder you need to view? Click the link below to look
            up your order through our secured site.
          </p>
          <button className="order-lookup-button" onClick={handleOrderLookupClick}>
            LOOK UP ORDER
          </button>
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default OrderLookUp;
