import dayjs from 'dayjs';

export interface IQueue {
  id?: number;
  orderId?: number | null;
  status?: boolean | null;
  createdAt?: string | null;
}

export const defaultValue: Readonly<IQueue> = {
  status: false,
};
