import { IProductInCart } from 'app/shared/model/product-in-cart.model';
import { IOrder } from 'app/shared/model/order.model';
import { IPayment } from 'app/shared/model/payment.model';
import { IUser } from 'app/shared/model/user.model';

export interface ICart {
  id?: number;
  totalAmount?: number | null;
  productsDiscountAmount?: number | null;
  cartDiscountAmount?: number | null;
  cartDiscountPercentage?: string | null;
  cartDiscountCodes?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  productInCarts?: IProductInCart[] | null;
  orders?: IOrder[] | null;
  payments?: IPayment[] | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<ICart> = {};
