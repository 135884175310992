import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import logoImage from '';

const PaypalDriverWhiz = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">Paypal Driver Whiz</div>
        </div>
      </div>
      <div className="container">
        <div className="column">
          <p>
            PayPal is one of the most popular, simplest and fastest online payment service used today. The users can instantly sign up,
            receive and send payments via internet. Paypal services in approx. 38 countries with 20 million members enjoying its services.
          </p>
          <h3>Paypal Driver Whiz</h3>
          <p>
            This payment method allows the users to make secure payment over the internet with their bank account or credit card easily.
            Driver Whiz is an effective <a href="http://driverwhiz.com/">driver updater tool</a> to offer you free unlimited scan but the
            driver update process will cost you with the charge based on the packages.
          </p>
          <p>
            We provide you 24*7 support services to clear your queries and take assistance from the support team. Also, If you are
            dissatisfied for any reason, just contact us and we will issue the requested{' '}
            <a href="http://driverwhiz.com/support/refunds">refund within 60 days</a>.
          </p>

          <p>
            You can buy DriverWhiz online by making payment through Paypal. The interested users can signup on Paypal and make payments.
          </p>

          <p>Also Related</p>
          <ul>
            <li>
              <a href="/SwpDriverRestoreCharge">SWP Driver Restore Charge</a>
            </li>
            <li>
              <a href="/SwpDriverWhizCharge">SWP Driver Whiz Charge</a>
            </li>
            <li>
              <a href="/PaypalSecureWebPayments">Paypal Secure Web Payments</a>
            </li>
            <li>
              <a href="/PaypalDriverRestore">Paypal Driver Restore</a>
            </li>
            <li>
              <a href="/PaypalSystemOptimizerPro">Paypal System Optimizer Pro</a>
            </li>
            <li>
              <a href="/BLSSecureWebPayments">BLS Secure Web Payments</a>
            </li>
          </ul>
          <p>
            <b>
              Do you have a billing question or require customer or technical support, the easiest way to get a quick response is to use the
              following Support form:
            </b>
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Your Name (required):</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Your Email (required)</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaypalDriverWhiz;
