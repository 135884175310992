import React, { useEffect, useState } from 'react';
import { CardGroup, Card, CardBody, CardTitle, Col, Row, CardText, Button, CardImg } from 'reactstrap';
import { getEntities } from '../../../../app/entities/product/product.reducer';
import { createEntity } from '../../../../override/app/modules/home-page/product.reducer';
import { useAppDispatch, useAppSelector } from '../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../app/shared/util/entity-utils';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from '../../../../app/shared/auth/private-route';
import { getSession } from '../../../../app/shared/reducers/authentication';
import { AUTHORITIES } from '../../../../app/config/constants';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Storage } from 'react-jhipster';
import { deleteEntity } from 'app/entities/product-in-cart/product-in-cart.reducer';
import { AxiosResponse } from 'axios';
import { IProductAsset } from '../../../../app/shared/model/product-asset.model';

import { ADDProduct } from 'app/shared/model/add-product.model';
import { getEntities as getCartDetails } from 'app/entities/product-in-cart/product-in-cart.reducer';
import { cardNumber } from 'card-validator/dist/card-number';

interface Props {
  cardCols?: string;
}

export const ProductListView = (props: Props) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isNew = id === undefined;
  const dataList = useAppSelector(state => state.product.entities);
  const updateProductInCart = useAppSelector(state => state.addProduct?.updating);
  const [posthogFlag, setPosthogFlag] = useState('');
  const [cartId, setCartId] = useState('');
  const [hideItems, setHideItems] = useState(false);
  const [atcCountCheck, setAtcCountCheck] = useState(0);

  useEffect(() => {
    dispatch(getEntities({ query: `productCategoryId.equals=1` }));
    if (window && window.screen.width < 767 && location.pathname === '/') {
      window.onscroll = function () {
        const scrolled = document.documentElement.scrollTop;
        setVisible(scrolled > 100);
      };
    }

    Storage.session.remove('productId');
  }, [id, dispatch, location.pathname]);

  const addToCart = async (productId: string) => {
    try {
      Storage.session.set('productId', productId);
      const productDetails = dataList.find(product => product.id === productId);
      const addProductDetails = {
        productId: productDetails?.id,
        quantity: 1,
        discountCodes: productDetails?.discountCodes || null,
      };

      const existingCartID = localStorage.getItem('cartId');
      if (existingCartID) {
        const cartDetailsList: any = await dispatch(getCartDetails({ query: `cartId.equals=${existingCartID}` }));
        const productList = cartDetailsList?.payload?.data || [];
        const productExist = productList.some(item => item?.product?.id === productId);
        if (productExist) {
          navigate('/checkout');
          return;
        }
      }

      const { payload } = await dispatch(createEntity(addProductDetails));
      if (payload['status']) {
        ReactGA.event({
          category: productDetails?.name,
          action: 'add to cart',
          value: productDetails?.price,
          transport: 'xhr',
        });
        ReactPixel.track('AddToCart', { content_name: productDetails?.name, value: `${productDetails?.price}`, currency: 'USD' });

        const cartId = payload['data'];
        setCartId(cartId?.id);
        setAtcCountCheck(prevState => prevState + 1);
        localStorage.setItem('cartId', cartId?.id);
        if (hideItems) {
          const anchor = document.querySelector('#shipping');
          anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          navigate('/checkout');
        }
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const getImageUrl = (imageObj: any) => {
    if (imageObj.path !== '') {
      return imageObj.path;
    } else {
      return `content/images/${imageObj.name}`;
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (isNew) {
      dispatch(getEntities({}));
    } else {
      dispatch(getEntities({ query: `id.in=${id}` }));
    }
  }, [dispatch, id, isNew]);

  return (
    <div>
      <div className="swpnotification">
        <div className="swpnotification_content">
          <h1>Did your Credit Card or Bank Statement reference a purchase from SecureWebPayments.com?</h1>
          <p>
            For Customer Support on your software purchase or to refund your order, please email
            <br />
            <a href="mailto:support@securewebpayments.com" target="_blank" rel="noopener noreferrer">
              support@securewebpayments.com
            </a>{' '}
            or call 1 (925) 892-3044 for immediate assistance.
          </p>
          <p className="notification_line_text">Hours of Operation are from 9am – 6pm Pacific Standard Time.</p>
        </div>
      </div>
      <Card className="swp_products">
        {dataList.map((product, i) => (
          <Card key={i} className="text-decoration-none mb-3">
            <CardBody>
              <CardTitle tag="h5">{product?.title}</CardTitle>
              <CardText style={{ float: 'left', width: '78%', padding: '0px 0px 20px' }}>
                <strong>Description:</strong> {product?.description}
                <br />
                <div className="price-tag">Price: {product?.price}</div>
                <div className="product_buttons" style={{ display: 'flex', marginTop: '10px' }}>
                  <div className="buynow_button">
                    <button
                      className="btn btn-primary"
                      onClick={() => addToCart(product.id)}
                      style={{ backgroundColor: '#fff', color: '#1172c1', marginRight: '10px' }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </CardText>

              <CardImg
                top
                width={124}
                height={155}
                src={product.productAssets && product.productAssets.length > 0 && getImageUrl(product.productAssets[0])}
                alt="Sample"
                className="alignnoneimage"
                style={{ maxWidth: '124px', height: 'auto' }}
              />
            </CardBody>
          </Card>
        ))}
        {!dataList ||
          (dataList.length === 0 && (
            <div className="alert alert-warning">
              <h2>No Product found</h2>
            </div>
          ))}
      </Card>
    </div>
  );
};

export default ProductListView;
