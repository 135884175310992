import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Sidebar from './sidebar';

const SwpDriverRestoreCharge = () => {
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">SWP Driver Restore Charge</div>
        </div>
      </div>
      <div className="two-column-layout">
        <div className="content">
          <p>
            To buy <a href="https://driverrestore.com/">Driver Restore</a>, simply click on ‘Buy Now’ button from where the user will be
            redirected to the cart page. SWP is a payment process that allows the users to easily buy the PC programs. The users will be
            able to make purchase from the credit card showing statement as PCSOFTWARESHOP.COM, 383media.com, DRIVER.U-BILL.COM
          </p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="../../../../content/images/driver_restore-box-en.png"
              alt="Centered Image"
              style={{ maxWidth: '100%', height: 'auto' }}
            />

            <div className="order_btn" style={{ marginTop: '20px' }}>
              <a href="http://driverrestore.com/download/">DOWNLOAD</a>
              <a href="/checkout">BUY NOW</a>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default SwpDriverRestoreCharge;
