import { getEntities as getUpsellCart } from 'app/entities/product-in-cart/product-in-cart.reducer';
import { createEntity } from '../../../../../override/app/modules/home-page/product.reducer';
import { getEntity as getOrderDetails } from 'app/entities/order/order.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useRef, useState } from 'react';
import { Storage } from 'react-jhipster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Table } from 'reactstrap';
import { AddressType } from 'app/shared/model/enumerations/address-type.model';

interface Props {}
export const ThankYou: React.FC = (props: Props) => {
  const slickRef = useRef(null);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const isNew = id === undefined;
  const product = useAppSelector(state => state.product.entities);
  const orderDetails = useAppSelector(state => state.order.entity);
  const loading = useAppSelector(state => state.productInCart.loading);
  const upSellProductsCart = useAppSelector(state => state.productInCart.entities);
  const orderId = parseInt(localStorage.getItem('orderId'));
  const totalPrice = parseInt(localStorage.getItem('totalPrice'));
  const addressId = parseInt(localStorage.getItem('addressId'));
  const checkUpsellProduct = JSON.parse(localStorage.getItem('checkUpsellProduct'));
  const [quantityOfProduct, setQuantityOfProduct] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hideUpsell, setHideUpsell] = useState(true);
  const [upSellCart, setUpSellCart] = useState();
  const [orderDetailsQuantity, setOrderDetailsQuantity] = useState(0);
  useEffect(() => {
    if (isNaN(orderId) == false) {
      // ReactPixel.init('1729701367468489');
      // ReactPixel.pageView();
      // ReactGA.initialize('G-9GECVX94CM');

      dispatch(getOrderDetails(orderId));

      // if (localStorage.getItem('hideUpsell') != 'true') {
      //   dispatch(getEntities({ query: `productCategoryId.in=2,3&order`, sort: `id,asc` }));
      //   // setHideUpsell(true);
      // }
      // if (localStorage.getItem('hideUpsell') == 'true') {
      //   setHideUpsell(true);
      // }
    }
    if (window) {
      window.onbeforeunload = function () {
        // if (localStorage.getItem('hideUpsell') == 'true') {
        localStorage.clear();
        // }
      };
    }
    return function cleanup() {
      // if (localStorage.getItem('hideUpsell') == 'true') {
      localStorage.clear();
      // }
    };
  }, []);

  useEffect(() => {
    let orderWarrantyQuantity = orderDetails?.productInCarts
      ?.map(obj => {
        let quantity = obj.quantity;
        let name = obj.product.name;
        let nameQuantity = parseInt(name) > 0 ? parseInt(name) : 1;
        quantity = nameQuantity * quantity;
        return quantity;
      })
      .reduce((a, b) => a + b, 0); // Provide 0 as the initial value

    setOrderDetailsQuantity(orderWarrantyQuantity);
  }, [orderDetails]);

  const nextSlick = () => {
    {
      if (slickRef.current.props.children.length - 1 == currentSlide) {
        if (localStorage.getItem('cartId') == null) {
          localStorage.setItem('hideUpsell', 'true');
          setHideUpsell(true);
        } else {
          navigate('/checkout');
        }
      }
      slickRef.current.slickNext();
    }
  };
  const getImageUrl = imageObj => {
    if (imageObj.path != '') {
      return imageObj.path;
    } else {
      return `content/images/${imageObj.name}`;
    }
  };
  const handleQuantityChange = (productId, change) => {
    setQuantityOfProduct(prevQuantities => {
      const updatedQuantity = (prevQuantities[productId] || 1) + change;
      // Apply quantity constraints
      const constrainedQuantity = Math.min(Math.max(updatedQuantity, 1), 5);
      return {
        ...prevQuantities,
        [productId]: constrainedQuantity,
      };
    });
  };

  let settings = {
    dots: false,
    slidesToShow: 1,
    speed: 500,

    centerPadding: '200px',
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const addToCartNewProduct = async productId => {
    Storage.session.set('productId', productId);
    const productDetails = product.find(product => product.id === productId);
    const addProductDetails = {
      productId: productDetails.id,
      quantity: quantityOfProduct[productId] || 1,
      discountCodes: productDetails.discountCodes || null,
    };

    let upSellCartPayload;
    if (localStorage.getItem('cartId') != '') {
      upSellCartPayload = await dispatch(getUpsellCart({ query: `cartId.equals=${localStorage.getItem('cartId')}` }));
    }

    if (productDetails.name.toLowerCase() === 'extended warranty') {
      let warrantyProducts = upSellCartPayload?.payload?.data?.filter(obj => obj.product.name.toLowerCase().includes('miracle smile'));
      let totalWarrantyQuantity = warrantyProducts?.length > 0 ? warrantyProducts.map(obj => obj.quantity).reduce((a, b) => a + b, 0) : 0;

      addProductDetails['quantity'] = parseInt(totalWarrantyQuantity) + orderDetailsQuantity;
    }

    const { payload } = await dispatch(createEntity(addProductDetails));

    if (payload['status']) {
      // ReactGA.event({
      //   category: productDetails.name,
      //   action: 'add to cart',
      //   value: productDetails.price,
      //   transport: 'xhr',
      // });
      // ReactPixel.track('AddToCart', { content_name: productDetails.name, value: `${productDetails.price}`, currency: 'USD' });
      const cart = payload['data'];
      setUpSellCart(cart);
      // localStorage.setItem('cartId', cart.id);
      localStorage.setItem('cartId', cart.id);
      // navigate('/cart');
      nextSlick();
      toast.success('Product Successfully Add to Cart');
    } else {
      toast.error('Unable to Add Product to Cart');
    }
  };
  const getProductName = name => {
    if (name != '') {
      switch (name) {
        case 'Driver Restore':
          return 'Driver Restore';
          break;
        case 'Driver Whiz':
          return 'Driver Whiz';
          break;

        case 'System Optimizer Pro':
          return 'System Optimizer Pro';
          break;
      }
    }
  };

  const getEmailAdress = orderDetails?.addresses?.map(item => {
    return item.id == addressId ? item.email : '';
  });
  const calculateTotal = (orderDetails, propName) => {
    const payments = orderDetails?.payments || [];
    const values = payments.map(obj => obj?.[propName] || 0);

    const total = values.reduce((a, b) => a + b, 0);
    return total > 0 ? total.toFixed(2) : '0.00';
  };
  let getDateDetails = new Date();
  let todayDate = getDateDetails.getMonth() + 1 + '-' + getDateDetails.getDate() + '-' + getDateDetails.getFullYear();

  return (
    <>
      {/* <!--  testimonial section start --> */}
      {isNaN(orderId) ? (
        <section id="center" className="center_slide1 product_design py-5 " style={{ height: 'calc(100vh - 395px)' }}>
          <div className="thank-heading text-center">
            {' '}
            <div className="display-4 text-center fw-bold mb-1"> Not Allowed </div>
          </div>
        </section>
      ) : (
        <section
          id="center"
          className="center_slide1 product_design py-5 container"
          // style={product.length == 0  ? { height: 'calc(100vh - 395px)' } : {}}
        >
          <div className="thank-heading text-center">
            <img src="content/images/check.png" />
            {/* <div className="display-4 text-center fw-bold mb-1"> Thank You</div> */}
            <p className="fs-5 mb-0">
              <strong>Order Number Is :</strong> {`#${orderDetails.orderNumber || orderDetails.id}`}
              {/* <strong>Shipping Method :</strong>{' '} {calculateTotal(orderDetails, 'shippingCharge') > 0 ? 'Standard' : 'Free'} */}
            </p>
          </div>
          {!hideUpsell ? (
            <Slider
              ref={slickRef}
              {...settings}
              className="center_slide"
              afterChange={current => {
                setCurrentSlide(current);
              }}
            >
              {product.length > 0 &&
                product.map((item, index) => (
                  <div className="slider-card ">
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <div className="offer-content">
                          <h2>{getProductName(item.name)}</h2>
                        </div>

                        {item.productAssets && item.productAssets.length > 0 && (
                          <img src={getImageUrl(item.productAssets[0])} className="img-responsive mb-2" />
                        )}
                      </div>
                      <div className="col-lg-8 col-md-12">
                        <h3 className="fs-5">{item.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>

                        <div>
                          {item.productCategory.name.toLowerCase() === 'quantity' && (
                            <div className="product-quantity mb-3">
                              <div className="d-flex justify-content-between">
                                <div className="input-group w-auto ">
                                  <input
                                    type="button"
                                    value="-"
                                    className="button-minus border rounded-circle  icon-shape icon-sm mx-1 "
                                    data-field="quantity"
                                    onClick={() => handleQuantityChange(item.id, -1)}
                                  />
                                  <input
                                    type="number"
                                    step="1"
                                    max="10"
                                    value={quantityOfProduct[item.id] || 1}
                                    name="quantity"
                                    className="quantity-field border-0 text-center "
                                  />
                                  <input
                                    type="button"
                                    value="+"
                                    className="button-plus border rounded-circle icon-shape icon-sm "
                                    data-field="quantity"
                                    onClick={() => handleQuantityChange(item.id, 1)}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="d-flex gap-2 align-items-center block">
                          <div className="price fs-1">Only ${item.price}</div>
                          {item.msrp && <p className=" text-decoration-line-through fs-5 mb-0"> Regular: ${item.msrp}</p>}
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 block">
                          <div className="fs-clr">
                            <button
                              className="btn mt-0  btn-primary fw-normal content-btn me-2"
                              onClick={() => {
                                addToCartNewProduct(item.id);
                              }}
                            >
                              Yes! Add to Order
                            </button>
                            <button className="btn mt-0  btn-light fw-normal content-btn me-2" onClick={nextSlick}>
                              No Thanks
                            </button>
                          </div>
                          {item.price > 0 ? <p className="mb-0 fs-clr">Free Shipping</p> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          ) : (
            <Table
              width="100%"
              style={{
                fontFamily: '"Montserrat", sans-serif',
                borderCollapse: 'collapse',
                backgroundColor: '#efefef',
                maxWidth: '600px',
                margin: '0 auto',
              }}
              cellSpacing={0}
              cellPadding={0}
              border={0}
              className="main"
            >
              <tbody>
                <tr>
                  <td>
                    <Table className="order-details" width="100%" cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: '0',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: '#F0F0F0',
                                width: '100%',
                                padding: '1px 0px',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                boxSizing: 'border-box',
                              }}
                            >
                              <p
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  marginBottom: 0,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                }}
                              >
                                <b>Please check your order details below:</b>
                              </p>
                              <p
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                Order Date:<b> {todayDate}</b>
                              </p>
                              <p
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                  marginTop: 0,
                                }}
                              >
                                Order Total: <b> ${calculateTotal(orderDetails, 'paymentAmount')}</b>
                              </p>
                              <p
                                style={{
                                  borderBottom: '1px solid #ddd',
                                  paddingBottom: '10px',
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                }}
                              >
                                You will receive an email confirmation shortly and your tracking number will be sent to:&nbsp;
                                <b>{getEmailAdress}</b>
                              </p>
                              <Table width="100%" cellSpacing={0} cellPadding={0}>
                                <tbody>
                                  <tr className="address-details">
                                    {orderDetails &&
                                      orderDetails.addresses &&
                                      orderDetails.addresses.map(item => (
                                        <td style={{ border: 0, width: '50%' }}>
                                          <div style={{ background: '#ddd', padding: '15px' }}>
                                            {' '}
                                            <b
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                              }}
                                            >
                                              {item.type == AddressType.BillingAddress ? 'Billing Address' : 'Shipping Address'}
                                            </b>
                                            <p
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 300,
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                                margin: 0,
                                              }}
                                            >
                                              {item.firstName + ' ' + item.lastName}
                                            </p>
                                            <p
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 300,
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                                margin: 0,
                                              }}
                                            >
                                              {item.street}
                                            </p>
                                            {item.street2 && (
                                              <p
                                                style={{
                                                  fontStyle: 'normal',
                                                  fontWeight: 300,
                                                  marginTop: 0,
                                                  fontSize: '14px',
                                                  lineHeight: '30px',
                                                  color: '#000000',
                                                  margin: 0,
                                                }}
                                              >
                                                {item.street2}
                                              </p>
                                            )}
                                            <p
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 300,
                                                marginTop: 0,
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                                margin: 0,
                                              }}
                                            >
                                              {item.city + ' ' + item.state + ' ' + item.countryCode}
                                            </p>
                                            <p
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 300,
                                                marginTop: 0,
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                                margin: 0,
                                              }}
                                            >
                                              {item.contact}
                                            </p>
                                          </div>
                                        </td>
                                      ))}
                                  </tr>
                                </tbody>
                              </Table>
                              <p
                                className="item-order"
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                  padding: '15px 10px',
                                  background: '#ddd',
                                }}
                              >
                                <b>ITEMS ORDERED</b>
                              </p>
                              <Table
                                style={{ borderColor: '#f2f2f2', margin: '20px auto 0px', width: '100%' }}
                                cellSpacing={0}
                                cellPadding={0}
                                border={0}
                                align="center"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        borderBottom: '1px solid #ddd',
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>
                                        Item Name
                                        <b />
                                      </b>
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'right',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>
                                        Quantity
                                        <b />
                                      </b>
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'right',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>
                                        Price
                                        <b />
                                      </b>
                                    </td>
                                  </tr>
                                  {orderDetails &&
                                    orderDetails.productInCarts &&
                                    orderDetails.productInCarts.map(productInCarts => (
                                      <tr>
                                        <td
                                          style={{
                                            fontWeight: 500,
                                            borderBottom: '1px solid #ddd',
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            color: '#333',
                                            padding: '5px',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {productInCarts.product.title}(${productInCarts.price})
                                        </td>
                                        <td
                                          style={{
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            borderBottom: '1px solid #ddd',
                                            textAlign: 'center',
                                            color: '#333',
                                            padding: '5px',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          <b>
                                            {productInCarts.quantity}
                                            <b />
                                          </b>
                                        </td>
                                        <td
                                          style={{
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            borderBottom: '1px solid #ddd',
                                            textAlign: 'right',
                                            color: '#333',
                                            padding: '5px',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          <b>
                                            ${productInCarts.price * productInCarts.quantity}
                                            <b />
                                          </b>
                                        </td>
                                      </tr>
                                    ))}
                                  <tr>
                                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'left',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Tax
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'right',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>
                                        <b />
                                      </b>
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        textAlign: 'right',
                                        borderBottom: '1px solid #ddd',
                                        fontSize: '14px',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>${calculateTotal(orderDetails, 'tax')}</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'left',
                                        color: '#162f70',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>Total</b>
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'right',
                                        color: '#333',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>
                                        <b />
                                      </b>
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 500,
                                        textAlign: 'right',
                                        borderBottom: '1px solid #ddd',
                                        fontSize: '14px',
                                        color: '#162f70',
                                        padding: '5px',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <b>${calculateTotal(orderDetails, 'paymentAmount')}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>

                              <Table width="100%" cellSpacing={0} cellPadding={0} style={{ paddingTop: '20px' }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div style={{ marginBottom: '30px' }}>
                                        <p
                                          style={{
                                            marginBottom: 0,
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#000000',
                                          }}
                                        >
                                          Thank You
                                        </p>
                                        <p
                                          style={{
                                            marginTop: 0,
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#000000',
                                          }}
                                        >
                                          Secure Web Payments
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {/* {product.length > 0 && (
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                className="btn btn-secondary content-btn mt-5 mb-3"
                style={{ fontSize: '1.6rem !important' }}
                onClick={() => {
                  navigate('/checkout');
                }}
              >
                Checkout
              </button>
            </div>
          </div>
        )} */}
        </section>
      )}

      {/* <!--  testimonial section start --> */}
    </>
  );
};

export default ThankYou;
