import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';

const ViewOrder = () => {
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const name = searchParams.get('name');
  const lastFourDigits = searchParams.get('lastFourDigits');
  const showCardInput = true;

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.post(`http://localhost:8094/api/order/order-look-up`, { email, name, lastFourDigits, showCardInput });
        setOrderData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching order data:', error);
        setIsLoading(false);
      }
    };

    if (email) {
      fetchOrderData();
    }
  }, [email, name, lastFourDigits, showCardInput]);

  return (
    <div className="view-order">
      <h1>Order Details</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : orderData.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Order Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orderData.map((order, index) =>
              order.productInCarts.map((product, productIndex) => (
                <tr key={`${index}-${productIndex}`}>
                  <th scope="row">{index * order.productInCarts.length + productIndex + 1}</th>
                  <td>{order.orderNumber}</td>
                  <td>{product.product.title}</td>
                  <td>${product.price}</td>
                  <td>{product.quantity}</td>
                  <td>{order.createdAt}</td>
                  <td>{order.status}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      ) : (
        <p>No orders found for the provided email</p>
      )}
    </div>
  );
};

export default ViewOrder;
