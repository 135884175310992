import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import logoImage from '';

const PaypalDriverRestore = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">Paypal Driver Restore</div>
        </div>
      </div>
      <div className="container">
        <div className="column">
          <p>
            Paypal is the easiest payment service that instantly allows you to make payments electronically to the businesses or other
            person. This <a href="/">payment method</a> is the fastest, oldest and the most trusted services to receive and send money
            anywhere around the globe.
          </p>
          <p>
            Driver Restore is the unique solution as an <a href="http://driverrestore.com/">windows driver updater</a> tool for the users.
            The software provides you with the free scan but you need to purchase the program to enjoy the drivers update process also the
            users can take assistance anytime from our support representatives.
          </p>
          <p>
            We provide 60 days money back policy. The user can ask for a{' '}
            <b>
              <a href="http://driverrestore.com/legal/refund-policy/">refund within 60 days</a>
            </b>{' '}
            from purchasing the software.
          </p>
          <p>
            Driver restore can be easily purchased online and you can instantly make payment via PayPal, the most trusted service online.
          </p>

          <p>Related</p>
          <ul>
            <li>
              <a href="/SwpDriverRestoreCharge">SWP Driver Restore Charge</a>
            </li>
            <li>
              <a href="/SwpDriverWhizCharge">SWP Driver Whiz Charge</a>
            </li>
            <li>
              <a href="/PaypalSecureWebPayments">Paypal Secure Web Payments</a>
            </li>
            <li>
              <a href="/PaypalSystemOptimizerPro">Paypal System Optimizer Pro</a>
            </li>
            <li>
              <a href="/PaypalDriverWhiz">Paypal Driver Whiz</a>
            </li>
            <li>
              <a href="/BLSSecureWebPayments">BLS Secure Web Payments</a>
            </li>
          </ul>
          <p>
            <b>
              Do you have a billing question or require customer or technical support, the easiest way to get a quick response is to use the
              following Support form:
            </b>
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Your Name (required):</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Your Email (required)</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaypalDriverRestore;
