import React, { useState } from 'react';
import axios from 'axios';

interface FormData {
  name: string;
  email: string;
  lastFourDigits: string;
  showCardInput: boolean;
}

const GuestTracking = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    lastFourDigits: '',
    showCardInput: true,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    name: '',
    email: '',
    lastFourDigits: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;

    if (name === 'showCardInput' && !checked) {
      setFormData({ name: '', email: '', lastFourDigits: '', showCardInput: false });
    } else {
      setFormData({ ...formData, [name]: val });
    }

    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: { [key: string]: string } = {};
    if (!formData.name.trim()) {
      newErrors.name = 'Please provide your name';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Please provide your e-mail address';
    }
    if (formData.lastFourDigits && !formData.lastFourDigits.trim()) {
      newErrors.lastFourDigits = 'Please provide last 4 digits of your Credit Card';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Redirect to ViewOrder page with email as query parameter
      window.location.href = `/ViewOrder?email=${formData.email}&name=${formData.name}&lastFourDigits=${formData.lastFourDigits}`;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="guest_tracking">
      <h1 style={{ fontWeight: 'normal' }}>Your Orders</h1>
      <form onSubmit={handleSubmit} className="std" noValidate>
        <fieldset className="account_fields">
          <p>Reviewing your order is simple. Please enter the information you used to make your purchase into the fields below:</p>
          <p className="text">
            <label className="label2">Name:</label>
            <input type="text" className="input round_8 w212" name="name" value={formData.name} onChange={handleChange} />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </p>
          <p className="text">
            <label className="label2">E-mail:</label>
            <input type="email" className="select_box_big input round_8 w212" name="email" value={formData.email} onChange={handleChange} />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </p>
          {formData.showCardInput && (
            <p className="text">
              <label className="label2">Last 4 digits of your Credit Card:</label>
              <input
                type="text"
                name="lastFourDigits"
                className="input round_8 w212"
                value={formData.lastFourDigits}
                onChange={handleChange}
              />
              {errors.lastFourDigits && <span className="error-message">{errors.lastFourDigits}</span>}
            </p>
          )}
          <p className="text">
            <label className="label2"></label>
            <input type="checkbox" name="showCardInput" id="showCardInput" checked={formData.showCardInput} onChange={handleChange} />
            &nbsp;Click this box if you did<strong> not </strong>pay with a Credit Card
          </p>
          <p className="center">
            <button type="submit" className="exclusive_large round_8" name="submitGuestTracking">
              <span className="guest-tracking"> View my order</span>
            </button>
          </p>
        </fieldset>
      </form>
      <div id="bottom-of-page">
        <div className="money-back">
          <p style={{ padding: '27px 0 0' }}>
            <strong>Money-Back guarantee!</strong> There is a 60 day full money-back guarantee for all orders!
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuestTracking;
