import axios from 'axios';
import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const SupportMessage = () => {
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2>Contact Us</h2>
        </div>
      </div>
      <h2>Need More Information?</h2>
      <p>
        Have a question that you could not find the answer to? Our support team is available 24 hours a day to make sure that all your
        concerns are addressed as soon as possible.
      </p>
    </div>
  );
};

const ContactType = {
  CONTACT_US: 'CONTACT_US',
};

const Contact = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    contactType: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const formDataWithContactType = {
      ...formData,
      contactType: ContactType.CONTACT_US,
    };
    const result = await axios.post('http://localhost:8094/api/email/contact-us', formDataWithContactType);

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
      contactType: '',
    });
  };

  return (
    <div>
      <SupportMessage />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>

        <button type="submit">Submit</button>
      </form>
      <div>
        <p>By clicking “Submit“, I agree to Site Terms and Conditions and Privacy Policy.</p>
      </div>
    </div>
  );
};

export default Contact;
