import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import logoImage from '';

const BLSSecureWebPayments = () => {
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">BLS Secure Web Payments</div>
        </div>
      </div>
      <div className="container">
        <div className="column">
          <h4>What Is BLS*Securewebpayments on your credit card statement?</h4>
          <p>
            The product-based website, Securewebpayments, facilitates users with secure and safe environment for carrying out their online
            credit card transactions. Users from across the globe consider the website to purchase the varied products sold on it. BLS or
            BlueSnap is a safe payment gateway chosen by the official website to let users experience a convenient way to{' '}
            <a href="/">online payments</a>.
          </p>
          <p>
            The payment processor aids in a hassle-free and secure online credit card payment. A user is always reluctant in opting for
            online payment method due to several reasons, however, BlueSnap offers a guarded platform for the users to carry out safe online
            transactions.
          </p>
          <h4>BLS Secure Web Payments</h4>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="../../../../content/images/BLSSecureWebPayments.jpg"
              alt="Centered Image"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
          <p>
            BlueSnap integrated with Securewebpayments allows the user to opt for a smooth online credit card transaction. BLS ensures that
            the credit card details that the user can input are kept safe and prevent the details from being misused.
          </p>
          <p>
            Nevertheless, for the safety of the users even Securewebpayments abides by the standards set by VeriSign™ Extended Validation
            and TRUSTe™ Privacy Certification for 3rd party. It is the main priority of the website to ensure that the payments process is
            user-friendly and safe too.
          </p>
          <p>
            While, BLS offers the user with a multi-language, multi-country, multi-currency experience, Securewebpayments provide the users
            with 24/7 customer care assistance. The user can communicate with the purchase related related or any other queries when
            required.
          </p>
          <p>
            Securewebpayments even have implemented a simple to follow refund policy for the users, via which users can obtain refund within
            15 days. Moreover, the website even provides the user with the option to pay through either BLS or credit card. BLS on the other
            hand supports payment made through varied credit cards, including Visa, MasterCard, American Express, Discover, Diner’s Club,
            JCB etc. The user-experience is managed in a manner that it is easy and simple to manage from the user end.
          </p>

          <p>Related</p>
          <ul>
            <li>
              <a href="/SwpDriverRestoreCharge">SWP Driver Restore Charge</a>
            </li>
            <li>
              <a href="/SwpDriverWhizCharge">SWP Driver Whiz Charge</a>
            </li>
            <li>
              <a href="/PaypalSecureWebPayments">Paypal Secure Web Payments</a>
            </li>
            <li>
              <a href="/PaypalDriverRestore">Paypal Driver Restore</a>
            </li>
            <li>
              <a href="/PaypalDriverWhiz">Paypal Driver Whiz</a>
            </li>
            <li>
              <a href="/PaypalSystemOptimizerPro">Paypal System Optimizer Pro</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BLSSecureWebPayments;
