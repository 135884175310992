import { IProductAsset } from 'app/shared/model/product-asset.model';
import { IProductTag } from 'app/shared/model/product-tag.model';
import { IProductCategory } from 'app/shared/model/product-category.model';
import { IProductInCart } from 'app/shared/model/product-in-cart.model';

export interface IProduct {
  id?: number;
  title?: string | null;
  description?: string | null;
  msrp?: number | null;
  price?: number | null;
  discountAmount?: number | null;
  discountPercentage?: string | null;
  active?: boolean | null;
  name?: string | null;
  sku?: string | null;
  productAssets?: IProductAsset[] | null;
  productTags?: IProductTag[] | null;
  productCategory?: IProductCategory | null;
  productInCarts?: IProductInCart[] | null;
}

export const defaultValue: Readonly<IProduct> = {
  active: false,
};
