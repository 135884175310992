import { IOrder } from 'app/shared/model/order.model';

export interface IKey {
  id?: number;
  customerId?: string | null;
  serialKey?: string | null;
  order?: IOrder | null;
}

export const defaultValue: Readonly<IKey> = {};
