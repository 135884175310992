import { IProduct } from 'app/shared/model/product.model';
import { IOrder } from 'app/shared/model/order.model';
import { ICart } from 'app/shared/model/cart.model';
import { IPayment } from 'app/shared/model/payment.model';

export interface IProductInCart {
  id?: number;
  price?: number | null;
  quantity?: number | null;
  discount?: number | null;
  discountCodes?: string | null;
  product?: IProduct | null;
  order?: IOrder | null;
  cart?: ICart | null;
  payment?: IPayment | null;
}

export const defaultValue: Readonly<IProductInCart> = {};
