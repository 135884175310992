import React, { useState } from 'react';
import Sidebar from './sidebar';

const FAQ = () => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [activeTab, setActiveTab] = useState('all');

  const handleAccordionClick = index => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const handleTabChange = tabName => {
    setActiveTab(tabName);
  };

  const accordionItems = [
    {
      title: 'What is the Secure Web Payments Automatic Renewal service?',
      content:
        'This service allows you to conveniently maintain your product subscription going forward, ensuring that you continue to receive the last software updates. Before you are charged with the renewal, we will email to inform you about the subscription price (equal to or less then the current price), and the date you will be charged, giving you the opportunity to cancel the automatic renewal.If you would like to turn off the automatic renewal service, you may cancel your enrollment at any time by email our customer care team at support@securewebpayments.com.',
      category: ['all', 'billing'],
    },
    {
      title: 'How can I contact the merchant whose product I purchased?',
      content:
        'You can contact the software publisher directly by selecting ‘View Your Online Order’. The digital receipts emailed to you following your order also detail this information.',
      category: ['all', 'technical support'],
    },
    {
      title: 'Why Does My Credit Card Statement show a transaction stating ‘SecureWebPayments.com’?',
      content:
        'When an order is made through Secure Web Payments online shopping cart (using Credit Card or PayPal), you&#8217;ll see www.securewebpayments.com on your credit card statement.',
      category: ['all', 'billing'],
    },
    {
      title: 'What is a Registration Key?',
      content:
        '	A license key or registration key is a unique code required to access product information that becomes available after purchase.You would have received a Registration key through the confirmation page following the order and also in an email sent by securewebpayements.com.',
      category: ['all', 'misc'],
    },
    {
      title: 'I havent received a Registration key how do I get access to one?',
      content:
        'Registration / License keys are delivered via two methods:On the confirmation page following your initial order.In the email you received from securewebpayments.com following your order.If you need to locate your registration key, please click on ,Order Lookup, then select ,Order ID, under Track Your Orders, You should now see , View Installation Instructions,  Here you will find your Registration key. You can also email support@securewebpayments.comand a customer support representative will gladly help you out.',
      category: ['all', 'customer support'],
    },
    {
      title: 'How can I ensure Secure Web Payments is a secure way to purchase my order?',
      content:
        'SecureWebPayments.com meets the vigorous 3rd party standards of VeriSign™ Extended Validation and TRUSTe™ Privacy Certification.',
      category: ['all', 'security'],
    },
    {
      title: 'What is VeriSign Extended Validation?',
      content:
        'Secure Sockets Layers (SSL) are used to encrypt private information transmitted over the web. Extended Validation SSL Certificates provide high security Internet browser information in order to enable third-party verification by authenticating the identity of a web site.</p>',
      category: ['all', 'security'],
    },
    {
      title: 'How do I get a refund?',
      content:
        'To request a refund for a product that you purchased using Secure Web Payments please email our Customer Care team at support@securewebpayments.com.',
      category: ['all', 'customer support'],
    },
    {
      title: 'How long will it take for a refund to appear in my account?',
      content:
        'Due to differences in processing times by all of the major credit cards, Refunds can take up to 15 business days. If your refund is taking longer than 15 days to process with your bank, Please let us know and we will do our best to provide you with any further information on the status of your refund. If you made a purchase using PayPal, please sign into your PayPal account to review your purchase history. Depending on your PayPal account preferences you may need to transfer funds from your PayPal account to your bank account.',
      category: ['all', 'customer support'],
    },
    {
      title: 'How do I get my refund if the card that I used to make a purchase is no longer valid?',
      content:
        'Refunds are always sent to the same card or account that was used to make the purchase. If the card used is no longer active it is recommended to contact your credit card provider to confirm where your refund will be applied.Usually, credit card providers will forward any outstanding credits to the replacement card. Unfortunately, our system is unable to issue refund checks at this time',
      category: ['all', 'customer support'],
    },
  ];

  const filteredAccordionItems = accordionItems.filter(item => item.category.includes(activeTab));

  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2>FAQ</h2>
        </div>
      </div>
      <div className="tabs">
        <button className={`tab ${activeTab === 'all' ? 'active' : ''}`} onClick={() => handleTabChange('all')}>
          All
        </button>
        <button className={`tab ${activeTab === 'billing' ? 'active' : ''}`} onClick={() => handleTabChange('billing')}>
          Billing
        </button>
        <button className={`tab ${activeTab === 'customer support' ? 'active' : ''}`} onClick={() => handleTabChange('customer support')}>
          Customer Support
        </button>
        <button className={`tab ${activeTab === 'misc' ? 'active' : ''}`} onClick={() => handleTabChange('misc')}>
          Misc
        </button>
        <button className={`tab ${activeTab === 'security' ? 'active' : ''}`} onClick={() => handleTabChange('security')}>
          Security
        </button>
        <button className={`tab ${activeTab === 'technical support' ? 'active' : ''}`} onClick={() => handleTabChange('technical support')}>
          Technical Support
        </button>
      </div>
      <div className="accordion" id="accordionExample">
        {filteredAccordionItems.map((item, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className={`accordion-button ${openAccordion === index ? 'collapsed' : ''}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                {item.title}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className={`accordion-collapse collapse ${openAccordion === index ? 'show' : ''}`}
              aria-labelledby={`heading${index}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <strong>{item.content}</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
