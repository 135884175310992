import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import logoImage from '';

const PaypalSecureWebPayments = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">Paypal Secure Web Payments</div>
        </div>
      </div>
      <div className="container">
        <div className="column">
          <p>
            Paypal is a globally respected online payment method, while SecureWebPayments is a secure payment process utilized by millions
            of users across the globe. Users have the option of paying for their purchase through PayPal which is integrated with Secure Web
            Payments. The users can easily buy products online by paying through PayPal or with credit cards on{' '}
            <a href="/">Secure Web Payments</a>. Using the free sign up service, a new user can link their credit card with{' '}
            <a href="https://www.paypal.com/">PayPal</a>. If the user chooses to pay through PayPal, they will be redirected to a safe
            online credit card transaction process, with the purchase amount added to the user’s credit card statement.
          </p>
          <p>
            <b>What Does Paypal*SecureWebPayments On My Credit Card Statement Means?</b>
          </p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="../../../../content/images/PayPalSecureWebPayments.jpg"
              alt="Centered Image"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
          <p>
            PayPal and SecureWebPayments both ensure that the credit card details provided by the users are protected. Users can purchase
            their product online and then can choose <b>PayPal incorporated with SecureWebPayment gateway for the billing</b>. Since,
            SecureWebPayments is one of the most secure payment portals, it processes all online credit card transactions using PayPal as
            the payment method. Credit card details linked with the PayPal account are used to make easy and secure credit card
            transactions.
          </p>
          <p>
            To ensure that the user follows a secure path to online payments, SecureWebPayments always follows the 3rd party standards
            benchmarked by VeriSign™ Extended Validation and TRUSTe™ Privacy Certification..
          </p>
          <p>
            SecureWebPayments and PayPal follow strict measures to prevent fraud, identity theft, email phishing etc. Vigorous standards
            ensure that credit card information is safe. Both services also provide 24/7 <a href="/contact">customer care support</a>. A
            user can send through their queries. They have user-friendly refund policies. They both assist the user by supporting various
            currencies and different languages.
          </p>
          <p>Related</p>
          <ul>
            <li>
              <a href="/SwpDriverRestoreCharge">SWP Driver Restore Charge</a>
            </li>
            <li>
              <a href="/SwpDriverWhizCharge">SWP Driver Whiz Charge</a>
            </li>
            <li>
              <a href="/BLSSecureWebPayments">BLS Secure Web Payments</a>
            </li>
            <li>
              <a href="/PaypalDriverRestore">Paypal Driver Restore</a>
            </li>
            <li>
              <a href="/PaypalDriverWhiz">Paypal Driver Whiz</a>
            </li>
            <li>
              <a href="/PaypalSystemOptimizerPro">Paypal System Optimizer Pro</a>
            </li>
          </ul>
          <p>
            <b>
              Do you have a billing question or require customer or technical support, the easiest way to get a quick response is to use the
              following Support form:
            </b>
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Your Name (required):</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Your Email (required)</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaypalSecureWebPayments;
