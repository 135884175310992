import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Key from './key';
import KeyDetail from './key-detail';
import KeyUpdate from './key-update';
import KeyDeleteDialog from './key-delete-dialog';

const KeyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Key />} />
    <Route path="new" element={<KeyUpdate />} />
    <Route path=":id">
      <Route index element={<KeyDetail />} />
      <Route path="edit" element={<KeyUpdate />} />
      <Route path="delete" element={<KeyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default KeyRoutes;
