import { getEntity, updateEntity, createEntity, reset } from 'app/entities/cart/cart.reducer';

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CartFormView = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const cart = useAppSelector(state => state.cart.entity);
  const loading = useAppSelector(state => state.cart.loading);
  const updateSuccess = useAppSelector(state => state.cart.updateSuccess);
  const updating = useAppSelector(state => state.cart.updating);

  const handleClose = () => {
    navigate(`/checkout`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...cart,

      ...values,
    };

    if (isNew) {
      await dispatch(createEntity(entity));
    } else {
      await dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...cart,
        };

  let entityFields = ['name', 'email', 'phone'];

  return (
    <div className="mx-5 mt-3 mb-4">
      <Row>
        <Col md="12">
          <h2>Create or edit a Cart</h2>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm className="mt-2 p-3 rounded-1 shadow" defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label="Name"
                id="name"
                name="name"
                data-cy="name"
                type="text"
                validate={{ required: { value: true, message: 'Name is required' } }}
              />
              <ValidatedField
                label="Email"
                id="email"
                name="email"
                data-cy="email"
                type="text"
                validate={{
                  required: { value: true, message: 'Email is required' },
                }}
              />
              <ValidatedField
                label="Phone"
                id="phone"
                name="phone"
                data-cy="phone"
                type="text"
                validate={{
                  required: { value: true, message: 'Phone is required' },
                }}
              />
              <Button tag={Link} id="cancel-save" className="rounded-5" to="/" outline color="dark">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="success" id="save-entity" type="submit" outline disabled={updating} className="ms-2 rounded-5">
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CartFormView;
