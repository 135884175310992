import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderActivity from './order-activity';
import OrderActivityDetail from './order-activity-detail';
import OrderActivityUpdate from './order-activity-update';
import OrderActivityDeleteDialog from './order-activity-delete-dialog';

const OrderActivityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderActivity />} />
    <Route path="new" element={<OrderActivityUpdate />} />
    <Route path=":id">
      <Route index element={<OrderActivityDetail />} />
      <Route path="edit" element={<OrderActivityUpdate />} />
      <Route path="delete" element={<OrderActivityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderActivityRoutes;
