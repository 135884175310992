import './header.scss';
import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, NavLink, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; // Import Dropdown components
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AboutUs from '../../../../override/app/modules/home-page/AboutUs';
import Contact from '../../../../override/app/modules/home-page/Contact';
import TermsAndConditions from '../../../../override/app/modules/home-page/TermsAndConditions';
import PrivacyPolicy from '../../../../override/app/modules/home-page/PrivacyPolicy';
import RefundPolicy from '../../../../override/app/modules/home-page/RefundPolicy';
import FAQ from '../../../../override/app/modules/home-page/FAQ';
import OrderLookUp from '../../../../override/app/modules/home-page/OrderLookUp';
import Products from '../../../../override/app/modules/home-page/Products';
import SwpDriverRestoreCharge from '../../../../override/app/modules/home-page/SwpDriverRestoreCharge';
import SwpDriverWhizCharge from '../../../../override/app/modules/home-page/SwpDriverWhizCharge';
import PaypalSecureWebPayments from '../../../../override/app/modules/home-page/PaypalSecureWebPayments';
import PaypalDriverRestore from '../../../../override/app/modules/home-page/PaypalDriverRestore';
import PaypalDriverWhiz from '../../../../override/app/modules/home-page/PaypalDriverWhiz';
import PaypalSystemOptimizerPro from '../../../../override/app/modules/home-page/PaypalSystemOptimizerPro';
import BLSSecureWebPayments from '../../../../override/app/modules/home-page/BLSSecureWebPayments';
import LoadingBar from 'react-redux-loading-bar';
import { Link as ScrollLink } from 'react-scroll';
import { Home, Brand } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { setLocale } from 'app/shared/reducers/locale';
import { useAppDispatch } from 'app/config/store';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  // State variables for managing dropdowns
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const [legalDropdownOpen, setLegalDropdownOpen] = useState(false);
  const [chargeDropdownOpen, setChargeDropdownOpen] = useState(false);

  const toggleSupportDropdown = () => setSupportDropdownOpen(!supportDropdownOpen);
  const toggleLegalDropdown = () => setLegalDropdownOpen(!legalDropdownOpen);
  const toggleChargeDropdown = () => setChargeDropdownOpen(!chargeDropdownOpen);

  return (
    <>
      {props.isAuthenticated ? (
        <>
          <header id="header">
            <nav data-cy="navbar" className="navbar shadow navbar-expand-md">
              <div className="container-fluid">
                {/* <Link className="navbar-brand" to="/">
                  <img src="content/images/logo.svg" />
                </Link> */}
                <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                <Collapse isOpen={menuOpen} navbar>
                  <Nav id="header-tabs" className="ms-auto dashboard" navbar>
                    {props.isAuthenticated && !props.isAdmin && (
                      <NavItem>
                        <NavLink tag={Link} to="/order" className="d-flex align-items-center">
                          <FontAwesomeIcon icon="asterisk" />
                          <span>Orders</span>
                        </NavLink>
                      </NavItem>
                    )}
                    {props.isAuthenticated && props.isAdmin && <Home />}
                    {props.isAuthenticated && props.isAdmin && <EntitiesMenu />}
                    {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
                    <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                    <AccountMenu isAuthenticated={props.isAuthenticated} />
                  </Nav>
                </Collapse>
              </div>
            </nav>
          </header>
        </>
      ) : (
        <nav className="navbar navbar-expand-lg navbar-light ">
          <a className="navbar-brand" href="/">
            Secure Web Payments
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  Home{' '}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Products">
                  Product
                </a>
              </li>
              <li className="nav-item dropdown">
                <Dropdown isOpen={supportDropdownOpen} toggle={toggleSupportDropdown}>
                  <DropdownToggle caret>Support</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="/FAQ">FAQ</DropdownItem>
                    <DropdownItem href="/Contact">Contact Us</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/OrderLookUp">
                  Order Look Up
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/AboutUs">
                  About Us
                </a>
              </li>
              <li className="nav-item dropdown">
                <Dropdown isOpen={legalDropdownOpen} toggle={toggleLegalDropdown}>
                  <DropdownToggle caret>Legal</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="/TermsAndConditions">Terms & Conditions</DropdownItem>
                    <DropdownItem href="/PrivacyPolicy">Privacy Policy</DropdownItem>
                    <DropdownItem href="/RefundPolicy">Refund Policy</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li className="nav-item dropdown">
                <Dropdown isOpen={chargeDropdownOpen} toggle={toggleChargeDropdown}>
                  <DropdownToggle caret>Charge</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="/SwpDriverRestoreCharge">Swp Driver Restore Charge</DropdownItem>
                    <DropdownItem href="/SwpDriverWhizCharge">Swp Driver Whiz Charge</DropdownItem>
                    <DropdownItem href="/PaypalSecureWebPayments">Paypal Secure Web</DropdownItem>
                    <DropdownItem href="/PaypalDriverRestore">Paypal Driver Restore</DropdownItem>
                    <DropdownItem href="/PaypalDriverWhiz">Paypal Driver Whiz</DropdownItem>
                    <DropdownItem href="/PaypalSystemOptimizerPro">Paypal System Optimizer Pro</DropdownItem>
                    <DropdownItem href="/BLSSecureWebPayments">BLS Secure WebPayments</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </>
  );
};

export default Header;
