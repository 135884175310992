import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Sidebar from './sidebar';

const TermsAndConditions = () => {
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2> Terms & Conditions</h2>
        </div>
      </div>
      <div className="two-column-layout">
        <div className="content">
          <p>
            <strong>Last Updated Date: [May 24, 2018]</strong>
          </p>
          <p>
            The website, www.securewebpayments.com (hereinafter “Site”) is operated by AbeApps Pte Ltd..(“AbeApps”). Throughout these Terms
            and Conditions, the terms “we”, “us” and “our” refer to AbeApps Pte Ltd. and the terms “you” and “your” refer to all users of
            the site, including without limitation users who are browsers and/or customers. The term “Products” refers to all products and
            services offered for use and/or sale on our site.
          </p>
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. YOUR USE OF ANY PART OF THIS SITE, INCLUDING BUT NOT LIMITED ACCESSING THE
            WEBSITE, SUBMITTING CONTENT, CONTACTING US THROUGH THE SITE, OR DOWNLOADING AND/OR PURCHASING PRODUCTS AND RELATED SERVICES
            THEREIN WILL INDICATE YOU ACCEPT THESE TERMS AND CONDITIONS IN FULL.{' '}
            <strong>
              IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, STOP ALL USE OF THIS SITE, PROMPTLY EXIT THIS SITE AND DELETE OR UNINSTALL
              ALL OF OUR PRODUCTS FROM YOUR COMPUTER.
            </strong>
          </p>
          <p>
            We reserve the right to update, change or replace any part of these Terms and Conditions by posting updates and/or changes to
            our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site
            following the posting of any changes constitutes acceptance of those changes. AbeApps may, at its sole discretion, inform you of
            these changes via email.
            <br />
            By your acceptance of these Terms and Conditions, you represent that you are at least the age of majority in your state or
            province of residence, or that you are the age of majority in your state or province of residence and you have given us your
            consent to allow any of your minor dependents to use this Site. You further represent that you possess the legal capacity to
            enter into a contract.
          </p>
          <h3>1. LIMITATIONS ON USE</h3>
          <p>
            You may not use this Site and/or our Products for any illegal or unauthorized purpose nor may you, in the use of the Site and/or
            Products, violate any laws in your jurisdiction (including but not limited to copyright laws). To the extent that your use of
            this Site and/or Products is restricted or in any way illegal in your jurisdiction, you agree to not to access or use this Site
            and/or Products.
          </p>
          <p>
            All pages within this Site and any material available for download are the property of AbeApps and/or its affiliates. No portion
            of the materials on these pages may be reprinted, modified, or distributed in any form without express written permission of
            AbeApps. You shall keep intact any proprietary notices contained on any download materials and shall comply with any applicable
            end user license agreements.
            <br />
            Any rights not expressly granted by these Terms and Conditions and any applicable end user license agreements are reserved by
            AbeApps.
          </p>
          <h3>2. TRADEMARK NOTICE</h3>
          <p>
            “AbeApps Inc.” and “Secure Web Payments” are the logos, trademarks, and service marks of AbeApps. All other trademarks, service
            marks, and logos used in this Site are the trademarks, service marks, or logos of their respective owners.
          </p>
          <h3>3.ORDER AND ACCEPTANCE</h3>
          <p>
            When you submit an order for a product to AbeApps, the order constitutes an offer to AbeApps to purchase the Products referred
            to on our Site and is subject to acceptance by AbeApps. Your receipt of an electronic or other form of order confirmation does
            not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. We reserve the right at any
            time after receipt of your order to accept, decline, or limit your order for any reason, whether or not your credit card has
            been charged. If your credit card has been charged and your order is canceled we will initiate a refund credit to your account.
            Your order is accepted when your payment has been accepted by AbeApps and you receive the license/registration key for the
            product purchased.
          </p>
          <p>
            We make every effort to maintain the availability of our Site. However, should we experience technical difficulties, we are not
            responsible for orders that are not processed or accepted.
          </p>
          <h3>4.PAYMENT TERMS AND TAX</h3>
          <p>
            Terms of payment are entirely within the discretion of AbeApps. Prices listed on the Site for Products are the prices in effect
            at that time and AbeApps reserves the right to withdraw any discount and/or revise any product price on the Site at any time
            prior to acceptance of your order. The final product price will be reflected prior to the confirmation of your order.
            <br />
            Unless otherwise specified, prices quoted on the Site are exclusive of tax, VAT, or duty of any kind which must be added to the
            price payable, where applicable. You agree to pay any applicable tax, VAT, or duty for the Products when you submit your order.
          </p>
          <p>
            Payment may ONLY be made via the payment options available on the Site. No other forms are being accepted at this time. You
            represent and warrant that the payment information you provide to us is: (A) true, correct, and complete; and (B) charges
            incurred by you will be honored by your payment company.
          </p>
          <p>
            Prior to our acceptance of your order, your payment must be received by us in full regardless of whether you are an individual
            or business.
            <br />
            Occasionally an error may occur in the price and/or description of a product on the Site, in which case AbeApps will contact you
            for instructions as to whether you would like to cancel the order or proceed and place the order based on a revised price or
            description of the product ordered.
          </p>
          <h3>5. DELIVERY AND ACTIVATION PROCESS</h3>
          <p>
            Many AbeApps products are primarily available for download only from our Site. It is your responsibility to access the Site,
            download the product, and follow the product installation and activation instructions. AbeApps is not liable in the event you do
            not or are unable to complete the download, installation, and/or activation processes. However, full refunds are available in
            accordance to the terms of our Satisfaction Guarantee.
          </p>
          <p>
            Where there are tangible products (i.e. compact disks) available for purchase from our Site:
            <br />
            (A) Delivery times and dates referenced on the Site or in an order communications from AbeApps are estimates only and we are not
            liable in the event of a delivery delay;
            <br />
            (B) AbeApps may charge you for extra costs arising from changes you make to your delivery address after you submit your order;
            <br />
            (C) Risk of loss of or damage to the Products pass to you upon delivery or when placed in your possession;
            <br />
            (D)If you refuse or fail to take delivery of Products provided in accordance with these Terms and Conditions, any risk of loss
            or damage to the Products shall nonetheless pass to you, without prejudice to any other rights or remedies AbeApps may have,
            including but not limited to:
            <br />
            (i) AbeApps shall be entitled to immediate payment in full for Products delivered and either to effect delivery by whatever
            means AbeApps deems fit, or to store Products at your risk and expense;
            <br />
            (ii) You shall be liable to pay, upon demand, all costs of product storage and any additional costs incurred as a result of your
            refusal or failure to take delivery; and
            <br />
            (iii) If you refuse to take delivery for a period of 30 days, AbeApps may dispose of the Products you ordered at AbeApps’s
            discretion.
          </p>
          <p>
            <strong>Please note:</strong> The Activation Process may involve third party call support centers based out of the United States
            of America, India, and/or other locations. These call support centers are affiliated with AbeApps ONLY to the extent that they
            aid you in the activation process. Any products or services made available for purchase by these calls support centers to you
            are not affiliated with AbeApps and such purchases are made completely at your own discretion between you and the call support
            center. Should you have any issues with your purchases with the call support center, please resolve the issue with the call
            support center directly. You are welcome to contact AbeApps via the contact information in Section 25 and we may provide you
            with the contact information for the call support center. AbeApps shall not be held liable for any purchases made beyond those
            offered directly by us and purchased directly from our Site.
          </p>
          <h3>6. SATISFACTION GUARANTEE</h3>
          <p>
            Under our Satisfaction Guarantee, you may cancel your order for any reason within the sixty (60) days after the day upon which
            your purchase was accepted by AbeApps. To exercise your right of cancellation under the Satisfaction Guarantee, you must contact
            AbeApps by using the information provided in Section 25. Your notice must include the following information: First and Last name
            of Customer and Email provided by Customer at time of purchase.
          </p>
          <h3>7.RENEWAL</h3>
          <p>When your License Term is close to expiring, we may send notices to the e-mail address you provided when you subscribed.</p>
          <p>
            (A) Automatic Renewal: We will automatically charge you and renew your subscription for another term when each license term
            expires if you have elected to automatically renew your license. The fee will not exceed the current price, excluding
            promotional and discount pricing. The new term will be the same length as the expired term unless otherwise specified by us at
            the time of renewal. This renewal and your payment will be processed within 2 days before/after the current term expires and
            each anniversary thereafter. We will inform you of your account status and any changes to the terms and conditions of your
            subscription in accordance with local law.
            <br />
            (B) Cancelling Automatic Renewal: You may cancel your automatic renewal at any time. If you do not want to be automatically
            charged, you must contact us to turn off automatic renewal before your current License Term ends via the contact information
            available in Section 25. Cancellation of automatic renewal stops charges on a going forward basis only and will not result in
            the retroactive refund of subscription payments. Furthermore, we will not pro-rate previously charged subscription fees on the
            basis of your cancellation date.
            <br />
            (c) Refund: If the subscription is automatically renewed and it is within 60 days of the date upon which you were charged for
            the renewal, we shall grant a full refund for that subscription charge upon your request. Please contact us via the information
            available in Section 25.
          </p>
          <h3>8.PRIVACY POLICY</h3>
          <p>
            We are committed to protecting your privacy. Please review Securewebpayment’s Privacy Policy available{' '}
            <a href="/privacy-policy/">here</a> and under Legal tab on the landing page of our Site. This Privacy Policy is incorporated by
            reference to these Terms and Conditions.
          </p>
          <h3>9.CUSTOMER INFORMATION</h3>
          <p>
            Your contact information is the only way by which we can contact you. It is solely your responsibility to ensure and maintain
            the accuracy of the contact information you provide. At any time you may update your customer account information by contacting
            us using the information provided in Section 25. AbeApps is not liable for any damages arising out of erroneous or un-updated
            customer information.
          </p>
          <h3>10. RESTRICTED ACCESS</h3>
          <p>
            Access to certain areas of this Site is restricted. AbeApps reserves the right to restrict access to any and/or the entire Site
            at AbeApps’s discretion. If AbeApps provides you with a user ID and password to enable you to access restricted areas of this
            Site or other content or services, you must ensure that the user ID and password are kept confidential. AbeApps is not liable
            for any damages arising out of your failure to keep the aforementioned user ID and password private.
          </p>
          <h3>11. SERVICE AND SUPPORT</h3>
          <p>
            All requests for technical service and support for Products made available for purchase on our Site can be made by contacting us
            using the information provided in Section 25. Availability for service and support vary by product and are described on our
            Site. We reserve the right to change or modify the availability of these services and support at any time, without notice. We
            will do our best to help you; however, we cannot guarantee that every problem will be resolved to your satisfaction.
          </p>
          <h3>12.TRANSFER</h3>
          <p>
            You may not alter, unbundle, or break the product down to components for distribution, transfer, resale, or for any other
            purpose. Further, you may not rent, lease, lend, redistribute, sublicense or transfer the Software to another party at any time.
            You are further prohibited from separating the product key from the associated software and/or transferring the product key to a
            third party for any purposes.
          </p>
          <p>
            If in AbeApps’s reasonable judgment, the acquisition of the product occurred using a fraudulently obtained credit care or any
            other unauthorized means, AbeApps has no obligations to provide customer services or support to you for the product in question
            or to allow the license to continue. AbeApps reserves the right to deactivate subscriptions obtained through any unauthorized
            means.
          </p>
          <h3>13. THIRD PARTY INTERACTION</h3>
          <p>
            During the use of this Site and Products, you may enter into correspondence with, purchase goods and/or services from, or
            participate in promotions of third party service providers, advertisers, or sponsors showing their goods and/or services through
            the Site or Products. Any such activity and any terms, conditions, warranties or representations associated with such activity
            is solely between you and the applicable third party. AbeApps and its licensors shall have no liability, obligation, or
            responsibility for any such correspondence, purchase, transaction, or promotion between you and any such third party. AbeApps
            makes no representation whatsoever about any other website that you may access through this Site. A hyperlink to a non-AbeApps
            website does not mean that AbeApps endorses or accepts any responsibility for the content or the use of the linked website. It
            is entirely your responsibility to take precautions to ensure that whatever you select for your use or download is free of such
            items as viruses, worms, Trojan horses, and other items of a destructive nature. In no event shall AbeApps or its licensors be
            held responsible for any content, products, services, or other materials on or available from such third party hyperlinks or
            providers.
          </p>
          <p>
            We may rely on third party advertising and marketing through the Site and/or Products and other mechanisms to finance the Site
            and/or Products. Per these Terms and Conditions you agree to receive such advertising and marketing. If you do not want to
            receive such advertising, stop using this Site and/or Products. You agree that it is your responsibility to take reasonable
            precautions in all interactions you may have with any third party you may interact with in the course of your use of the Site or
            Products.
          </p>
          <h3>14. INDEMNIFICATION</h3>
          <p>
            You accept that, as a limited liability entity, AbeApps has an interest in limiting the personal liability of its officers and
            employees. By agreeing to these Terms and Conditions and using this Site or services therein, you agree that you shall defend,
            indemnify, and hold AbeApps, its licensors, and each such party’s parent organizations, subsidiaries, affiliates, officers,
            directors, users, employees, attorneys, and agents harmless from and against any claims, costs, damages, losses, liabilities and
            expenses (including attorneys’ fees and costs) arising out of or in connection with:
            <br />
            (A) your violation or breach of any terms of these Terms and Conditions or any applicable law or regulation;
            <br />
            (B) your violation of the rights of any third party; and
            <br />
            (C) your use or misuse of the Site and/or Products.
          </p>
          <h3>15. DISCLAIMER OF WARRANTY</h3>
          <p>
            INFORMATION ON THIS SITE AND THE PRODUCTS ARE PROVIDED “AS IS”. TO THE EXTENT PERMITTED BY APPLICABLE LAW, AbeApps EXPRESSLY
            DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY APPLICABLE LAW, AbeApps MAKES NO WARRANTIES THAT:
          </p>
          <p>
            (A) PRODUCTS AND/OR SITE WILL MEET YOUR REQUIREMENTS;
            <br />
            (B) PRODUCTS OR SITE USE WILL BE UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE;
            <br />
            (C) THE RESULTS THAT MAY BE OBTAINED FROM THE PURCHASE OF THE PRODUCTS AND/OR USE OF THE SITE WILL BE ACCURATE OR RELIABLE;
            <br />
            (D) THE QUALITY OF ANY PRODUCTS PURCHASED OR INFORMATION OBTAINED BY YOU THROUGH THE PRODUCTS AND/OR SITE WILL MEET YOUR
            EXPECTATIONS; OR
            <br />
            (E) ANY ERRORS IN THE PRODUCTS AND/OR SITE WILL BE CORRECTED. AbeApps DOES NOT WARRANT THIRD PARTY PRODUCTS.
          </p>
          <h3>16. LIMITATION OF LIABILITY</h3>
          <p>
            SOME STATES AND JURISDICTIONS INCLUDING MEMBER COUNTRIES OF THE EUROPEAN ECONOMIC AREA, DO NOT ALLOW FOR THE LIMITATION OR
            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO THE BELOW LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND REGARDLESS OF WHETHER ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE,
            IN NO EVENT SHALL AbeApps BE HELD LIABLE TO YOU FOR ANY PERSONAL INJURY, INCIDENTAL, SPECIAL, CONSEQUENTIAL, INDIRECT, OR
            SIMILAR DAMAGES, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, CORRUPTION, OR LOST DATA, FAILURE TO TRANSMIT OR
            RECEIVE ANY DATA OR INFORMATION, BUSINESS INTERRUPTION, OR ANY COMMERCIAL DAMAGES OR LOSSES ARISING OUT OF OR RELATED TO THE USE
            OF THIS SITE OR PURCHASE OF PRODUCTS THEREIN EVEN IF ABEAPPS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO CASE
            SHALL ABEAPP’S LIABILITY EXCEED THE AMOUNT THAT YOU PAID FOR THE APPLICABLE PRODUCT OR $75, WHICHEVER AMOUNT IS THE LESSER.
          </p>
          <h3>17.FORCE MAJEURE</h3>
          <p>
            AbeApps is not responsible for any failure to perform due to unforeseen circumstances or to causes beyond our reasonable
            control, including but not limited to acts of God, war, riot, embargoes, acts of civil or military authorities,
            network/telecommunication failure or breakdown, fire, floods, accidents, strikes, lockouts, or shortages of transportation,
            facilities, fuel, energy, labor, or materials. In the event of any such delay, AbeApps may be excused from such performance to
            the extent that it is delayed or prevented by such cause.
          </p>
          <h3>18. EXPORT REGULATIONS</h3>
          <p>
            You acknowledge that Products may be subject to the import and export laws of the United States of America and the laws of any
            country where the product is imported or re-exported. You agree to comply with all relevant laws and will not export any product
            in contravention of such applicable laws to any prohibited country, entity, or person for which an export license or government
            approval is required. You hereby agree that you will not export or sell any product for use in connection with chemical,
            biological, or nuclear weapons, or missiles, drones or space launch vehicles capable of delivering such weapons.
          </p>
          <h3>19.WAIVER</h3>
          <p>
            The failure of AbeApps to enforce any of our rights under these Terms and Conditions, our policies, or any applicable laws shall
            not be deemed to constitute a waiver of such rights.
          </p>
          <h3>20.ASSIGNMENT</h3>
          <p>
            AbeApps may transfer, subcontract or otherwise deal with our rights and/or obligation under these Terms and Conditions without
            notifying you or obtaining your consent. You may NOT transfer, subcontract or otherwise deal with our rights and/or obligation
            under these Terms and Conditions.
          </p>
          <h3>21.SEVERABILITY</h3>
          <p>
            In the event that any provision or part of a provision of this License is determined to be unlawful, void, or unenforceable,
            such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion
            shall be deemed to be severed from this License. Such determination shall not affect the validity and enforceability of any
            other remaining provisions.
          </p>
          <h3>22.ENTIRE AGREEMENT</h3>
          <p>
            These Terms and Conditions and any policies or documents referenced herein constitute the entire agreement between you and
            AbeApps pertaining to the subject hereof and supersedes all prior agreements and understandings of the parties with respect
            thereto. To the extent that these Terms and Conditions conflict with anything referenced herein or anything in or associated
            with this Site is in conflict or inconsistent with these Terms and Conditions, these Terms and Conditions shall take precedence.
          </p>
          <h3>23.CHOICE OF LAW AND VENUE</h3>
          <p>
            This Site is controlled by us from our offices within Singapore. By accessing this Site and/or purchasing Products available
            through this Site, we agree that the statutes and laws of Singapore, without regard to the conflict of laws principles thereof,
            will apply. Each of us agrees and hereby submits to the exclusive personal jurisdiction and venue of the Supreme Court of the
            Republic of Singapore with respect to such matters.
          </p>
          <h3>24. STATUTE OF LIMITATIONS</h3>
          <p>
            Any cause of action brought by you against us or our affiliates must be instituted within one year after the cause of action
            arises or be deemed forever waived and barred.
          </p>
          <h3>25. CONTACT US</h3>
          <p>
            The preferred method for you to contact us is via our <a href="/contact-us/">Contact Us</a> form on our Site. In the
            alternative, you may reach us at:
          </p>
          <p>
            <strong>BY EMAIL:</strong>
            <br />
            support@securewebpayments.com
          </p>
          <p>
            <strong>BY MAIL:</strong>
            <br />
            AbeApps Pte. Ltd.
            <br />
            Street: 17 PHILLIP STREET
            <br />
            #05-01 GRAND BUILDING
            <br />
            SINGAPORE (SG) 048695
          </p>
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
