import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './key.reducer';

export const KeyDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const keyEntity = useAppSelector(state => state.key.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="keyDetailsHeading">
          <Translate contentKey="secureWebPaymentsApp.key.detail.title">Key</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{keyEntity.id}</dd>
          <dt>
            <span id="customerId">
              <Translate contentKey="secureWebPaymentsApp.key.customerId">Customer Id</Translate>
            </span>
          </dt>
          <dd>{keyEntity.customerId}</dd>
          <dt>
            <span id="serialKey">
              <Translate contentKey="secureWebPaymentsApp.key.serialKey">Serial Key</Translate>
            </span>
          </dt>
          <dd>{keyEntity.serialKey}</dd>
          <dt>
            <Translate contentKey="secureWebPaymentsApp.key.order">Order</Translate>
          </dt>
          <dd>{keyEntity.order ? keyEntity.order.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/key" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/key/${keyEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KeyDetail;
