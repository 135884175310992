// import HomePage from 'app/modules/home-page/home-page';
import Registration from 'app/modules/registration/registration';
// import Checkout from 'app/modules/checkout/checkout';
import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Checkout from './modules/checkout/checkout';
import AboutUs from '../../webapp/override/app/modules/home-page/AboutUs';
import Contact from '../../webapp/override/app/modules/home-page/Contact';
import TermsAndConditions from '../../webapp/override/app/modules/home-page/TermsAndConditions';
import PrivacyPolicy from '../../webapp/override/app/modules/home-page/PrivacyPolicy';
import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import OrderLookUp from '../../webapp/override/app/modules/home-page/OrderLookUp';
import Products from '../../webapp/override/app/modules/home-page/Products';
// import Cart from 'app/override/modules/home-page/cart';
import RefundPolicy from '../../webapp/override/app/modules/home-page/RefundPolicy';
import ThankYou from '../../webapp/override/app/modules/home-page/thank-you/thank-you';
import FAQ from '../../webapp/override/app/modules/home-page/FAQ';

import { HomePage } from '../../webapp/override/app/modules/home-page/home-page';
import GuestTracking from '../../webapp/override/app/modules/home-page/GuestTracking';

import SwpDriverRestoreCharge from '../override/app/modules/home-page/SwpDriverRestoreCharge';
import SwpDriverWhizCharge from '../override/app/modules/home-page/SwpDriverWhizCharge';
import PaypalSecureWebPayments from '../override/app/modules/home-page/PaypalSecureWebPayments';
import PaypalDriverRestore from '../override/app/modules/home-page/PaypalDriverRestore';
import PaypalDriverWhiz from '../override/app/modules/home-page/PaypalDriverWhiz';
import PaypalSystemOptimizerPro from '../override/app/modules/home-page/PaypalSystemOptimizerPro';
import BLSSecureWebPayments from '../override/app/modules/home-page/BLSSecureWebPayments';
import ViewOrder from '../override/app/modules/home-page/ViewOrder';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="checkout" element={<Checkout />} />
        {/* <Route path="cart" element={<Cart />} /> */}
        <Route path="thank-you" element={<ThankYou />} />
        {/* <Route path="contact-us" element={<Contact />} /> */}
        <Route path="/register" element={<Registration />} />
        <Route path="home" element={<HomePage />} />
        <Route path="/dashboard" element={<HomePage cardCols="2" />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/OrderLookUp" element={<OrderLookUp />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/ViewOrder" element={<ViewOrder />} />

        <Route path="/GuestTracking" element={<GuestTracking />} />

        <Route index element={<HomePage />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="SwpDriverRestoreCharge" element={<SwpDriverRestoreCharge />} />
        <Route path="SwpDriverWhizCharge" element={<SwpDriverWhizCharge />} />
        <Route path="PaypalSecureWebPayments" element={<PaypalSecureWebPayments />} />
        <Route path="PaypalDriverRestore" element={<PaypalDriverRestore />} />
        <Route path="PaypalDriverWhiz" element={<PaypalDriverWhiz />} />
        <Route path="PaypalSystemOptimizerPro" element={<PaypalSystemOptimizerPro />} />
        <Route path="BLSSecureWebPayments" element={<BLSSecureWebPayments />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
