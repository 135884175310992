import { IOrder } from 'app/shared/model/order.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';

export interface IOrderActivity {
  id?: number;
  newOrderId?: string | null;
  fromQuantity?: number | null;
  toQuantity?: number;
  activity?: ActivityType | null;
  orders?: IOrder[] | null;
}

export const defaultValue: Readonly<IOrderActivity> = {};
