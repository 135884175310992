import cart from 'app/entities/cart/cart.reducer';
import productInCart from 'app/entities/product-in-cart/product-in-cart.reducer';
import order from 'app/entities/order/order.reducer';
import paymentGateway from 'app/entities/payment-gateway/payment-gateway.reducer';
import product from 'app/entities/product/product.reducer';
import productCategory from 'app/entities/product-category/product-category.reducer';
import payment from 'app/entities/payment/payment.reducer';
import discountCode from 'app/entities/discount-code/discount-code.reducer';
import orderActivity from 'app/entities/order-activity/order-activity.reducer';
import queue from 'app/entities/queue/queue.reducer';
import productTag from 'app/entities/product-tag/product-tag.reducer';
import productAsset from 'app/entities/product-asset/product-asset.reducer';
import address from 'app/entities/address/address.reducer';
import paymentMethod from 'app/entities/payment-method/payment-method.reducer';
import tag from 'app/entities/tag/tag.reducer';
import key from 'app/entities/key/key.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  cart,
  productInCart,
  order,
  paymentGateway,
  product,
  productCategory,
  payment,
  discountCode,
  orderActivity,
  queue,
  productTag,
  productAsset,
  address,
  paymentMethod,
  tag,
  key,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
