import CartFormView from './CartFormView';

import ProductDetailsView from './ProductDetailsView';

import { getEntities } from '../../../app/entities/cart/cart.reducer';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
interface Props {
  cardCols?: string;
}

export const Registration = (props: Props) => {
  return (
    <div>
      <h1>Registration</h1>
      <Row>
        <Col>
          <CartFormView />
        </Col>

        {/* <Col>
          <ProductDetailsView />
        </Col> */}

        <span id="custom-components" />
      </Row>
    </div>
  );
};

export default Registration;
