import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import logoImage from '';

const PaypalSystemOptimizerPro = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <div className="order_title">Paypal System Optimizer Pro</div>
        </div>
      </div>
      <div className="container">
        <div className="column">
          <p>
            <a href="http://systemoptimizerpro.com/">System Optimizer Pro</a> offers an advanced analysis and scanning of your personal
            device. After a system scan has been performed all the users will be provided with an option to remove any invalid registry or
            file system entries found.
          </p>
          <p>
            Removal is provided at a charge while scanning and analysis are free of cost also, you can take help from our 24*7 customer
            support representatives in case of any issues.
          </p>
          <p>
            As per our policies, we provide full refund to the customers if they are not satisfied with the program. The user can ask for a
            refund from 60 days of the purchased order and also can easily buy the program online by making payment through the most trusted
            and <a href="/">secure payment</a> service, PayPal.
          </p>
          <p>PayPal offers its users the best, fastest and easiest way to transfer money online to the businesses or the person.</p>

          <p>Related</p>
          <ul>
            <li>
              <a href="/SwpDriverRestoreCharge">SWP Driver Restore Charge</a>
            </li>
            <li>
              <a href="/SwpDriverWhizCharge">SWP Driver Whiz Charge</a>
            </li>
            <li>
              <a href="/PaypalSecureWebPayments">Paypal Secure Web Payments</a>
            </li>
            <li>
              <a href="/PaypalDriverRestore">Paypal Driver Restore</a>
            </li>
            <li>
              <a href="/PaypalDriverWhiz">Paypal Driver Whiz</a>
            </li>
            <li>
              <a href="/BLSSecureWebPayments">BLS Secure Web Payments</a>
            </li>
          </ul>
          <p>
            <b>
              Do you have a billing question or require customer or technical support, the easiest way to get a quick response is to use the
              following Support form:
            </b>
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Your Name (required):</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Your Email (required)</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaypalSystemOptimizerPro;
