import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const AboutUs = () => {
  return (
    <div>
      <div className="page-title-container">
        <div className="page-title">
          <h2>About Us</h2>
        </div>
      </div>
      <div className="two-column-layout">
        <div className="content">
          <img src="../../../../content/images/about.jpg" alt="Order Lookup" className="order-lookup-icon" />
        </div>
        <div className="column">
          <h2>About Secure Web Payments</h2>
          <p>
            Secure Web Payments offers its clients a global payment platform based on years of conversion optimization experience with a
            primary focus on PC-based consumers. SWP specializes in PC Utility Software, including; Anti-Malware, PC Optimization, Driver
            Utility, and Data Recovery Software. This e-commerce solution has achieved the highest level e-commerce certifications,
            including TRUST Privacy Certification and VeriSign Extended Validation.
          </p>
          <p>
            Secure Web Payments also provides fraud protection services via both Merchant Account and World Wide Gateway providers. With
            Secure Web Payments, our clients are able to pay for their purchase using all major credit cards, bank transfers, pre-paid and
            post-paid cards, and e-wallet services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
